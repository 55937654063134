import React from "react";
import "./Banner.css";
import Common_Button from "../../Common-Component/Common_Button/Common_Button";


function Banner() {
  return (
    <section className="home-banner">
      <div className="container-fluid ">
        <div className="row p-0">
          <div className="col-md-12 p-0">
            <div className="image-holder p-0">
              <img className='ban-image' src={process.env.PUBLIC_URL + '/assest/image/home/banner.png'} alt="Logo" />
              <div className="overlay-color"></div>
              <div className="content-holder">
                <h1>The One-Stop Shop For All <br /> Your Corporate Gifting</h1>
                <p>armor your success: unveil the strengths of corporate shield gifts!</p>
                <div className="button-holder">
                  <Common_Button
                    text="TALK TO US"
                    className='talk-us'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;
