import React from "react";
import "./App.css";
import "./index.css";

import { Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Welcomepage from "./components/Welcomepage/Welcomepage";

function App() {
  const location = useLocation();
  const isWelcomePage = location.pathname === '/';
  return (
    <>
      <ScrollToTop />
      {!isWelcomePage && <Header />}

      <Routes>
        <Route path="/" element={<Welcomepage />} />
        <Route path="/home" element={<Home />} />
      </Routes>
      {!isWelcomePage && <Footer />}

    </>
  );
}

export default App;
